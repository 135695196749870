<template>
  <v-container class="ma-0 pa-0 mb-10" fluid>
    <div
      class="rowContentPattern"
      :style="{ height: $vuetify.breakpoint.smAndUp ? '200px' : '150px' }"
    ></div>
    <div
      class="ma-auto header d-flex justify-start"
      :style="{ width: $vuetify.breakpoint.smAndUp ? '60%' : '100%' }"
    >
      <v-row
        no-gutters
        class="ma-0"
        :style="{ marginBottom: $vuetify.breakpoint.smAndUp ? '10px' : '10px' }"
      >
        <v-col cols="12" sm="2" class="ma-sm-5 text-center">
          <v-img
            :src="calculatedImageId()"
            class="ma-auto"
            contain
            :style="{
              width: $vuetify.breakpoint.smAndUp ? '1500px' : '200px'
            }"
          ></v-img>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          :style="{ position: 'relative' }"
          class="text-sm-start text-center mb-2 mb-sm-0"
        >
          <div :class="{ headerTitle: $vuetify.breakpoint.smAndUp }">
            <div
              class="text-h3 primary--text mt-2"
              :style="{ fontFamily: 'Rancho, Ekatra, serif !important' }"
            >
              {{ title }}
            </div>
            <div class="text-h6 primary--text mt-2">
              {{ description }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <Breadcrumb />

    <div
      class="ma-auto mt-2"
      :style="{ width: $vuetify.breakpoint.smAndUp ? '80%' : '' }"
    >
      <slot></slot>
    </div>
  </v-container>
</template>

<script>
import Breadcrumb from './Breadcrumb.vue'
import Utils from '../../util/Utils'
export default {
  components: {
    Breadcrumb
  },
  props: {
    image: {
      type: String,
      required: true
    },
    title: { type: String, required: true },
    description: { type: String, required: false }
  },
  methods: {
    calculatedImageId() {
      return Utils.imgUrlByPath(`categories/${this.image}`)
    }
  }
}
</script>

<style scoped>
.rowContentPattern {
  background: url('../../assets/gplaypattern.png'),
    linear-gradient(
      252deg,
      rgba(176, 238, 214, 1) 2%,
      rgba(249, 249, 253, 1) 45%,
      rgba(171, 195, 231, 1) 93%
    );
  background-repeat: repeat;
  background-position: top left, top right;
  background-blend-mode: multiply;
  width: 100%;
  position: absolute;
}

.header {
  position: relative;
  height: 100%;
  width: 60%;
}
.headerContent {
  position: absolute;
  top: calc(20%);
}

.headerTitle {
  position: absolute;
  bottom: 60px;
  left: 120px;
}
.titleMobile {
  padding-top: 100px;
}
</style>
