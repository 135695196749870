var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"practice-play-card ma-sm-5 ma-1",style:({
    '--themecolor': _vm.themeColor,
    '--card-height': _vm.image ? '22rem' : '20rem',
    '--title-font-size': _vm.image ? '1rem' : '1.5rem',
    '--title-font-weight': _vm.image ? '600' : '800'
  }),on:{"mouseleave":function($event){_vm.rotate = false}}},[_c('div',{class:_vm.sideClass('front')},[(_vm.image)?_c('v-img',{staticClass:"practice-play-card__side-img",attrs:{"src":_vm.image,"contain":""}}):_vm._e(),_c('span',{staticClass:"practice-play-card__side-title"},[_vm._v(_vm._s(_vm.practicePlayData.name[_vm.$i18n.locale])+" "),_c('div',{staticClass:"practice-play-card__side-title-underline"})]),_c('p',[_vm._v(" "+_vm._s(_vm.practicePlayData.description[_vm.$i18n.locale] || _vm.practicePlayData.description.en)+" ")]),_c('div',{staticClass:"practice-play-card__side--action-block"},[_c('router-link',{staticClass:"mr-2 text-decoration-none",attrs:{"to":`/practice/${_vm.practicePlayData.categoryId}/${_vm.practicePlayData.id}`}},[_c('v-btn',{attrs:{"rounded":""}},[_vm._v("Play")])],1),(_vm.practicePlayData.studyMaterials.length > 0)?_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){_vm.rotate = !_vm.rotate}}},[_vm._v("mdi-book-open-page-variant")]):_vm._e(),(_vm.practicePlayData.pdf)?_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-book-open-page-variant")]):_vm._e(),(_vm.practicePlayData.video)?_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-filmstrip")]):_vm._e(),(_vm.practicePlayData.audio)?_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-volume-high")]):_vm._e()],1)],1),_c('div',{class:_vm.sideClass('back')},[_vm._m(0),_vm._l((_vm.practicePlayData.studyMaterials),function(m,index){return _c('a',{key:m.text.en,staticClass:"materialLink",attrs:{"href":m.url[_vm.$i18n.locale] || m.url.en,"target":"_blank"}},[_vm._v(" "+_vm._s(m.text[_vm.$i18n.locale])+" ")])}),_c('div',{staticClass:"practice-play-card__side--action-block"},[_c('router-link',{staticClass:"mr-2 text-decoration-none",attrs:{"to":`/practice/${_vm.practicePlayData.categoryId}/${_vm.practicePlayData.id}`}},[_c('v-btn',{attrs:{"rounded":""}},[_vm._v("Practice")])],1),_c('v-icon',{staticClass:"ml-1 mr-1",on:{"click":function($event){_vm.rotate = false}}},[_vm._v("mdi-rotate-3d-variant")])],1)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"practice-play-card__side-title mb-3"},[_vm._v("Study Material "),_c('div',{staticClass:"practice-play-card__side-title-underline"})])
}]

export { render, staticRenderFns }