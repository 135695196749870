<template>
  <PracticePlayPage
    v-if="category"
    :image="category.image"
    :title="category.name[$i18n.locale]"
    :description="category.description[$i18n.locale]"
  >
    <v-row justify="center">
      <v-col
        class="flex-grow-0"
        v-for="(item, index) in categoryItems"
        :key="item.id"
      >
        <PracticePlayCard
          :image="cardImage(item.id)"
          :practicePlayData="item"
          :themeColor="
            item.cardThemeColor || themeColors[index % themeColors.length]
          "
        />
      </v-col>
    </v-row>
  </PracticePlayPage>
</template>

<script>
import PracticePlayPage from '../components/common/PracticePlayPage.vue'
import PracticePlayCard from '../components/common/PracticePlayCard.vue'
import Utils from '../util/Utils'
export default {
  components: {
    PracticePlayPage,
    PracticePlayCard
  },
  data() {
    return {
      categoryItems: undefined,
      categoryId: undefined,
      themeColors: ['#4a1898', '#ff0070', '#0caba9']
    }
  },
  mounted() {
    this.buildCategoryItems(this.$route.params.categoryId)
  },
  computed: {
    category() {
      return this.$store.state.practicePlayCategories.find(
        (c) => c.id == this.categoryId
      )
    }
  },
  watch: {
    $route(to, from) {
      this.buildCategoryItems(to.params.categoryId)
    }
  },
  methods: {
    cardImage(id) {
      try {
        return Utils.imgUrl(`quizzes/${id}`, 'main.png')
      } catch (e) {
        return undefined
      }
    },
    buildCategoryItems(categoryId) {
      this.categoryId = categoryId
      let pages = [...this.$store.state.practicePlayPages]
      this.categoryItems = pages
        .filter((p) => p.categoryId == categoryId)
        .map((p) => {
          p.studyMaterials = this.$store.state.studyMaterial
            .filter((s) => s.pageIds && s.pageIds.indexOf(p.id) >= 0)
            .map((s) => {
              return { text: s.doc, url: s.url }
            })
          return p
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
